import { useAllergensQuery } from "@api";
import { colors, assets, TABLET_BREAKPOINT } from "@util/constants";
import { Container } from "@util/standard";
import React from "react";
import Dropdown from "react-dropdown";
import styled from "styled-components";
import { useStore } from "@state/store";
import { Image } from "@global";

const StyledDropdown = styled(Dropdown)`
  width: 230px;
  margin-bottom: 30px;
  border: 1.5px solid ${colors.merlin};
  border-radius: 3px;
  font-size: 15px;
  cursor: pointer;

  .Dropdown-arrow-wrapper{
    display: flex;
    width: 10px;
    padding: 19px;
    img{
      transform: rotate(180deg);
    }
  }
  &.is-open{
    .Dropdown-arrow-wrapper{
      img{
        transform: rotate(0);
      }
    }
  }

  .Dropdown-control {
    display: flex;
    height: 44px;
  }

  .Dropdown-placeholder {
    margin: auto auto auto 20px;
  }

  .Dropdown-menu {
    margin: auto auto auto 20px;

    .Dropdown-option {
      margin-top: 6px;
      margin-bottom: 6px;
      cursor: pointer;

      &:hover {
        font-weight: bold;
      }
    }
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px){
    .Dropdown-arrow-wrapper{
        padding: 10px;
    }
    .Dropdown-placeholder,
    .Dropdown-menu {
      margin: auto auto auto 10px;
    }
  }
`;

const arrowIcon = () => (
  <Image staticImage={assets.arrowUpDark} />
);

const AllergensFilter = () => {
  const { allSanityAllergen } = useAllergensQuery();
  const { allergenFilter } = useStore();

  // Populate allergens filter
  let options: any = [{ label: "None", value: "none" }];
  allSanityAllergen.nodes.forEach(allergen => {
    const item = {
      label: allergen.title,
      value: allergen._id,
    };
    options.push(item);
  });

  //Handle allergens change
  const handleChange = (value: any) => {
    useStore.setState({
      allergenFilter: {
        id: value.value,
        name: value.label,
      },
    });
  };

  const placeholder = (): string => {
    if (allergenFilter?.name === "None") {
      return 'Dietary Requirements';
    }
    if (allergenFilter?.name) {
      return allergenFilter?.name;
    }
    return 'Dietary Requirements';
  };

  return (
    <Container>
      <StyledDropdown
        className="downDownWrapper"
        options={options}
        placeholder={placeholder()}
        onChange={handleChange}
        arrowClosed={arrowIcon()}
        arrowOpen={arrowIcon()}
      />
    </Container>
  );
};
export default AllergensFilter;
