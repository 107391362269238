import { IconButton, Image, Link } from "@global";
import { SanityRecipeTag, SanityRecipeTagConnection } from "@graphql-types";
import { Container, P, H3 } from "@util/standard";
import styled from "styled-components";
import { assets } from "@util/constants";
import { StyledMethodContainer } from "@shared/shared.styles";

import * as React from "react";
import { navigate } from "gatsby";

interface Props {
  tags?: SanityRecipeTag[];
  relatedSanityRecipeTag: SanityRecipeTagConnection;
}

const GradientContainer = styled(Container)`
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.55));
`;

const StyledTitleContainer = styled(Container) <{ isOpen?: boolean }>`
  border-bottom: 1px solid white;
`;

const RelatedRecipeCategories = ({ relatedSanityRecipeTag }: Props) => {

  return (
    <StyledMethodContainer bacgkroundUrl>
      <Container
        width="80%"
        display="flex"
        flexDirection="column"
        margin="100px auto"
      >
        <Container>
          <H3 color="merlin">Related Recipes Occasions</H3>
          <Link
            margin="auto auto auto 30px"
            internallink
            alternateFontSize
            linktext="View All >"
          />
        </Container>
        <Container flexWrap="wrap">
          {relatedSanityRecipeTag?.nodes && relatedSanityRecipeTag.nodes.slice(0, 3).map(tag => (
            <Container key={tag._key} margin="7px 7px">
              <Image
                isBackground
                imageData={
                  tag.main?.hero?.videoUrl?.image?.asset?.gatsbyImageData
                }
              >
                <Container
                  height="390px"
                  width="380px"
                  flexDirection="column"
                  justifyContent="flex-end"
                >
                  <GradientContainer
                    cursor="pointer"
                    width="100%"
                    height="fit-content"
                  >
                    <Container
                      flexDirection="column"
                      width="80%"
                      margin="40px"
                      alignItems="flex-start"
                    >
                      <P
                        color="white"
                        fontSize={30}
                        bold
                        margin="0px 0px 25px 0px"
                      >
                        {tag.main?.title}
                      </P>
                      <StyledTitleContainer
                        width="80%"
                        margin="0px"
                        onClick={() =>
                          navigate(`/recipes/${tag.main?.slug.current}`)
                        }
                      >
                        <Container justifyContent="space-between" width="100%">
                          <P cursor="pointer" fontSize={15} color="white">
                            View all
                          </P>
                          <img src={assets.arrowRightWhite} width="10px" />
                        </Container>
                      </StyledTitleContainer>
                    </Container>
                  </GradientContainer>
                </Container>
              </Image>
            </Container>
          ))}
        </Container>
      </Container>
    </StyledMethodContainer>
  );
};

export default RelatedRecipeCategories;
